import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'

Vue.config.productionTip = false

Vue.use(ArgonDashboard)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// create axios global
Vue.prototype.$http = axios.create({
  baseURL: 'https://api.gserv.net'
})
